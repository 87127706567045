import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faDownload,
  faCheck,
  faSync,
  faStar,
  faChartBar,
  faChartLine,
  faPlus,
  faTrashAlt,
  faUser,
  faKey,
  faSitemap,
  faUsers,
  faTicketAlt,
  faCogs,
  faSlidersH,
  faAward,
  faDatabase,
  faInfoCircle,
  faMoneyBillWave,
  faDollarSign,
  faPencilAlt,
  faBullhorn,
  faUpload,
  faFileUpload,
  faFileImport,
  faComments,
  faShoppingBasket,
  faQrcode,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleLeft,
  faClone,
  faTrophy,
  faMedal,
  faMoneyBillAlt,
  faCircle,
  faTag,
  faCreditCard,
  faShoppingBag,
  faTruck,
  faPlay,  
  faFileCode,
  faEye,
  faSyncAlt,
  faPhotoVideo,
  faLink,
  faSortAmountDown,
  faListOl,
  faCopyright,
  faBuilding,
  faThumbsUp,
  faFileInvoiceDollar,
  faBookOpen, 
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faClock,
  faCheckCircle,
  faClipboardList,
  faSearch,
  faGlobeAmericas,
  faHeadset,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

library.add(faUserTie);
library.add(faHeadset);
library.add(faGlobeAmericas);
library.add(faDownload);
library.add(faCheck);
library.add(faSync);
library.add(faStar);
library.add(faChartBar);
library.add(faChartLine);
library.add(faPlus);
library.add(faTrashAlt);
library.add(faUser);
library.add(faKey);
library.add(faSitemap);
library.add(faUsers);
library.add(faTicketAlt);
library.add(faCogs);
library.add(faSlidersH);
library.add(faAward);
library.add(faDatabase);
library.add(faInfoCircle);
library.add(faMoneyBillWave);
library.add(faDollarSign);
library.add(faPencilAlt);
library.add(faBullhorn);
library.add(faUpload);
library.add(faFileUpload);
library.add(faFileImport);
library.add(faComments);
library.add(faShoppingBasket);
library.add(faQrcode);
library.add(faSquare);
library.add(faCheckSquare);
library.add(faAngleDoubleRight);
library.add(faAngleDoubleLeft);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faClone);
library.add(faTrophy);
library.add(faMedal);
library.add(faMoneyBillAlt);
library.add(faCircle);
library.add(faTag);
library.add(faCreditCard);
library.add(faShoppingBag);
library.add(faTruck);
library.add(faPlay);
library.add(faFileCode);
library.add(faEye);
library.add(faSyncAlt);
library.add(faPhotoVideo);
library.add(faLink);
library.add(faSortAmountDown);
library.add(faListOl);
library.add(faCopyright);
library.add(faBuilding);
library.add(faThumbsUp);
library.add(faFileInvoiceDollar);
library.add(faBookOpen);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faClock);
library.add(faCheckCircle);
library.add(faClipboardList);
library.add(faSearch);

export default library;
